@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.App {
  position:fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #030406;
  min-height: 100vh;
  text-align: center;
  overflow: auto;
 }

.star {
  position: absolute;
  width: 1px;
  height: .5px;
  border-radius: 50%;
  background-color: #BCD5E3;
  animation: twinkle 2ms infinite ease-out;
}

@keyframes twinkle {
  0% { opacity: 100; }
  50% { opacity: 50; }
  100% { opacity: 0; }
}

@media (prefers-reduced-motion: no-preference) {
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
}

.gear {
  animation: spin infinite 1s linear;
  font-size: 15px;
}

.App-header {
  min-height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: calc(1px + 2vmin);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-style:initial;
  margin-left: 5px;
  margin-right: 50px;
  /* animation: appear 1s ease-in-out forwards;  */
}

.App-header img {
  width: 110px;
}

.logo-text {
  display: flex;
  align-items: center;
}

.text-color {
  opacity: 100;
  color: #F2EEE7;
}

.kode-mono-text{
  font-family: "Kode Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style:normal;
  font-size: 13.5px;
}

.social-icons {
  display: flex;
  width: 10%;
  justify-content: space-between;
}

.responsive-image { 
  max-width: 30%; 
  height: auto; 
}

.App-body > div {
  border-radius: 15px;
  padding: 1em;
  margin-top: 5px;
  margin-left: 45px;
} 

.App-body{
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 100px 100px;
  gap: 10px;
  height: auto;
  margin-top: 0;
}

.box1 {
  grid-column: 3/7;
  grid-row: 1;
  background-image: linear-gradient(135deg,#1e1e20,#0e0e11 32%);
  outline: .1px solid #383D3F;
  animation: appear 0.5s ease-in-out forwards;
}

.delay1{
  opacity: 0;
  animation-delay: .5s; 
}

.animate{
  animation: appear 1s ease-in-out forwards; 
}

.box2 {
  background-image: url(../public/original.jpeg);
  grid-column: 1/3;
  grid-row: 1 / 3;
  height: 200px;
  background-size: cover; 
  background-repeat: no-repeat; 
  background-position: center -10px;
  outline: .1px solid #383D3F;
  animation: appear 0.5s ease-in-out forwards; 
}

.delay2{
  opacity: 0;
  animation-delay: .5s; 
}

.box3 {
  grid-row: 2;
  grid-column: 3/5;
  background-image: linear-gradient(135deg,#1e1e20,#0e0e11 32%);
  outline: .1px solid #383D3F;
  animation: appear 0.45s ease-in-out forwards; 
  /* position: relative; */
  overflow:auto;
}

.box3:before { /* Add this block */
  content: "";
  position: absolute;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  border-radius: 15px 0 0 15px;
  outline: .1px solid #383D3F;
  background: rgba(255, 255, 255, 0.03); /* Change color and opacity as needed */
  transition-delay: 5s;
}

.box3:hover:before { /* Add this block */
  left: 70%;
}

.delay3{
  opacity: 0;
  animation-delay: 1s; 
}

.badge {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px; /* adjust as needed */
  height: 30px; /* adjust as needed */
  /* background-image: url('icon.png'); replace with your icon image path */
  /* background-size: cover; */
}

.prj-title{
  opacity: 0;
  animation: appear 0.45s ease-in-out forwards; 
  animation-delay: 1s;
}

.box4 {
  grid-row: 2;
  grid-column: 5/7;
  background-image: linear-gradient(135deg,#1e1e20,#0e0e11 32%);
  outline: .1px solid #383D3F;
  animation: appear 0.45s ease-in-out forwards 
}

.delay4{
  opacity: 0;
  animation-delay: 1s; 
}

.box5 {
  grid-row: 3;
  grid-column: 1/3;
  height: 420px;
  background-image: linear-gradient(135deg,#1e1e20,#0e0e11 32%);
  outline: .1px solid #383D3F;
  animation: appear .45s ease-in-out forwards; 
}

.delay5{
  opacity: 0;
  animation-delay: 1s; 
}

.box6 {
  grid-row: 3;
  grid-column: 3/4;
  background-image: linear-gradient(135deg,#1e1e20,#0e0e11 32%);
  outline: .1px solid #383D3F;
  animation: appear 0.5s ease-in-out forwards; 
}

.delay6{
  opacity: 0;
  animation-delay: 1.25s; 
}

.box7 {
  grid-row: 3;
  grid-column: 4/7;
  background-image: linear-gradient(135deg,#1e1e20,#0e0e11 32%);
  outline: .1px solid #383D3F;
  animation: appear 0.5s ease-in-out forwards; 
}

.delay7{
  opacity: 0;
  animation-delay: 1.25s; 
}

.body-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px;
}

.text-gutter2 {
  grid-column: 4/6;
  grid-row: 2;
}

.text-gutter3 {
  grid-column: 3/4;
  grid-row: 3/4;
}

.research {
  grid-column: 4 / 7;
  grid-row: 3 / 4;   
}

.box-titles{
  opacity: 0;
  animation: appear 0.5s ease-in-out forwards; 
  animation-delay: 1.25s;
}

.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 10px;
  padding: 10px;
  width: 500px;
  margin-left: 100px;
  border-radius: 1%;
  background-image: linear-gradient(135deg,#1e1e20,#0e0e11 32%);
}

.grid-positions{
  display: flex;
  align-items: center;
  gap: 0.1%;
}

.App-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .95rem;
  position: relative;
  overflow: auto;
  top: 25px;
  line-height: .01;
  animation: appear .5s ease-in-out forwards;
  opacity: 0; 
  animation-delay: 1.30s;
  height: auto;
}

.size{
  font-size: 0.70rem;
  margin-right: 15px;
  width: .25%;
  height: .25%;
}

.gradient-wrapper {
  background-image: linear-gradient(135deg,#1e1e20,#0e0e11 32%);
  position: relative;
  border-radius: 15px 15px 0 0;
  margin-left: 45px;
  margin-right:45px;
  }